/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 21/04/2023.
 */

import React, { useState } from 'react'
import { Select } from 'antd'

import { useParams } from 'react-router-dom'
import { useProjects } from '../../hooks/useProjects'
import { USER_TYPES } from '../../Types'
import { analytics } from '../../util/analytics'

export const HeaderSelectProject = () => {
  const [state] = useState()
  const { projectId } = useParams() as { projectId: string }
  //const me = meStore((state) => state.me)

  const { data } = useProjects()

  const handleChange = (e, k) => {
    //const to = TRIAL_PAGE.goto(e.key)
    const href = window.location.origin

    // We need to know what your type is and what your sites are in case you're a clinician or cra
    const nextMe = data.find((obj) => {
      console.log('HeaderSelectProject > obj = ', obj)

      const key = obj.type === USER_TYPES.CLINICIAN ? 'projects' : 'projectId'

      return e === obj[key]?._id
    })

    // If you're a CLINICIAN or CRA, the dropdown needs to redirect u to /site/site._id
    if (nextMe.type === 'CLINICIAN') {
      // Redirect only if you have sites
      if (nextMe.sites.length > 0) {
        const newLocation = href + '/project/' + e.toString() + '/site/' + nextMe.sites[0]._id.toString()

        window.location.replace(newLocation)
        // If you do not have sites, redirect to main page if you try to open a different project
      } else {
        const newLocation = href

        window.location.replace(newLocation)
      }
      // If you're a CTM or DM, you should be redirected to the /overview page
    } else {
      const newLocation = href + '/project/' + e.toString() + '/overview'

      window.location.replace(newLocation)
    }
  }

  const items = data
    ? data
        .map((usersOnProject) => {
          const key = usersOnProject?.type === USER_TYPES.CLINICIAN ? 'projects' : 'projectId'
          //
          // This might happen if project has been deleted, but the reference is still connected to the user
          //
          if (!usersOnProject[key]) {
            return null
          }

          const item = { value: usersOnProject[key]?._id, label: usersOnProject[key]?.title }

          return item
        })

        .filter((item) => item !== null)
    : []

  const trackOpen = () => {
    analytics.track('OPEN_PROJECT_MENU')
  }

  const selectedProjectArr = items.filter((item) => item.value === projectId) // selected.length === 1 ? selected[0].projectId._id : '---'
  const value = selectedProjectArr.length === 1 ? selectedProjectArr[0].label : '---'
  return (
    <div onClick={trackOpen} className="d-flex w-100" style={{ minWidth: 150 }}>
      {projectId ? (
        <>
          <p className="p-small fw-bold" style={{ marginTop: 7 }}>
            Projects:
          </p>
          <Select
            popupMatchSelectWidth={false}
            variant="borderless"
            className="w-auto fw-bold"
            style={{ minWidth: 150 }}
            defaultValue={value}
            value={state}
            onChange={handleChange}
            placeholder={'--'}
            options={items}
          ></Select>
        </>
      ) : null}
    </div>
  )
}
