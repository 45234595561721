/*
export const analytics = () => {
  if (window.hasOwnProperty('umami')) {
    return window['umami']
  }
  return () => {}
}
*/

/*if (window.hasOwnProperty('umami')) {
	return window['umami']
}*/

/*if () {
	return window['umami']
}*/

export const analytics = window.hasOwnProperty('umami') ? window['umami'] : { track: (t, e) => {}, identify: (t) => {} }
