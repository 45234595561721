import { useParams } from 'react-router-dom'
import React from 'react'
import { useAssignments } from '../../hooks/useAssignment'
import { useProjectById } from '../../hooks/useProjectById'
import { Collapse, CollapseProps } from 'antd'
import { QuestionsTypes, QuestionType } from '../../Types'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

export const SubjectAssignment = () => {
  const { projectId, subjectId, assignmentId } = useParams() as {
    projectId: string
    subjectId: string
    assignmentId: string
  }

  const { data: projectData } = useProjectById(projectId)
  const { data } = useAssignments(projectId, assignmentId, subjectId)

  const defaultLang = projectData.languages[0].isoCode
  const questions = data.questions

  return (
    <div className="container-fluid">
      <div className="row" style={{}}>
        {questions.map((question, index) => {
          const title = question.title[defaultLang]
          const body = question.body[defaultLang]

          // Revert answer list. Last answer first
          const answers = data.answers.filter((answer) => answer.questionId === question._id).reverse()

          return (
            <div key={index} className="col-12 mb-4 border-bottom pb-4">
              <h5>{title}</h5>
              <p>{body}</p>

              <AnswersFactory question={question} answers={answers} defaultLang={defaultLang} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const AnswersFactory = ({ question, answers, defaultLang }: { question: any; answers: any[]; defaultLang: string }) => {
  const type: QuestionType = question.type
  const showAnswers = question?.type !== QuestionsTypes.info

  const formatDate = (dateString: string) => {
    dayjs.extend(utc)

    const created = dayjs.utc(dateString).format('DD MMMM YYYY, HH:mm:ss') + ' UTC'

    return `${created}`
  }

  const createLabel = (createdAt: string, deviceTime: string | null, i: number = 0) => {
    const label = i === 0 ? 'Submitted: ' : 'Answer: '
    return (
      <>
        {label + formatDate(createdAt)}
        {deviceTime ? <p className="mb-1 p-mini opacity-50">Device time: {deviceTime}</p> : null}
      </>
    )
  }

  /**
   For each question type:
   1. Create answers
   2. Wrap answers
   */
  const createAnswer = (type: QuestionType, answer: any) => {
    const hasValue = answer?.value
    const answerIndex = hasValue ? parseInt(answer.value) : null

    switch (type) {
      case QuestionsTypes.radio:
        // Create and wrap options
        return (
          <div>
            {question?.options.map((option, questionIndex) => {
              return (
                <RadioAnswerOption
                  key={`${answerIndex}-${questionIndex}`}
                  isSelected={answerIndex === questionIndex}
                  body={option[defaultLang]}
                />
              )
            })}
          </div>
        )
      case QuestionsTypes.nprs11pt2l:
        // Create and wrap options
        return (
          <div>
            <div className="d-flex justify-md-content-between justify-xml-content-start">
              {question?.options.intensity.map((option, questionIndex) => {
                return (
                  <NprsAnswerOption
                    key={`${answerIndex}-${questionIndex}`}
                    isSelected={answerIndex === questionIndex}
                    body={option[defaultLang]}
                  />
                )
              })}
            </div>
          </div>
        )
      default:
    }
  }

  if (showAnswers) {
    /*
    If there is ONE answer, options are wrapped in a <div>
    If there is MORE than one answer, options are wrapped in a <Collapse> creating an accordion
   */
    if (answers.length === 1) {
      // One answer
      const answer = answers[0]
      const label = createLabel(answer.createdAt, answer.deviceTime ? answer.deviceTime : null)
      return (
        <div className="mt-3">
          <div className="mb-2" style={{ fontSize: '0.8rem' }}>
            {label}
          </div>
          {createAnswer(type, answer)}
        </div>
      )
    } else if (answers.length > 1) {
      // Multiple answers
      const items: CollapseProps['items'] = answers.map((answer, i) => {
        return {
          key: (i + 1).toString(),
          label: createLabel(answer.createdAt, answer.deviceTime ? answer.deviceTime : null, i),
          children: <div style={{ marginTop: '-15px' }}>{createAnswer(type, answer)}</div>, // The negative top margin decreases distance between children and header label in collapse. Making layout a little more "tight"
          style: { borderBottom: '1px dotted #DDD' },
        }
      })

      return (
        <Collapse
          ghost
          accordion
          expandIconPosition={'right'}
          items={items}
          defaultActiveKey={['1']}
          size="small"
          style={{ fontSize: '0.8rem' }}
        />
      )
    }

    // If no answers to display, the question has been accessed anyway. Display the date for each "answer"
  } else {
    const elements = answers.map((answer, i) => {
      const label = createLabel(answer.createdAt, answer.deviceTime ? answer.deviceTime : null, i)
      return (
        <div key={`element-${i}`} className="">
          <div className="" style={{ fontSize: '0.8rem' }}>
            {label}
          </div>
        </div>
      )
    })
    return <div className="mt-3">{elements}</div>
  }

  return null
}

const RadioAnswerOption = ({ isSelected, body }: { isSelected: boolean; body: string }) => {
  return (
    <div
      className="p-2 me-2 border-1 border mb-1"
      style={{
        color: isSelected ? 'white' : 'black',
        background: isSelected ? '#6e6e6e' : 'transparent',
      }}
    >
      <p>{body}</p>
    </div>
  )
}

const NprsAnswerOption = ({ isSelected, body }: { isSelected: boolean; body: string }) => {
  return (
    <div
      className="p-2 me-2 border-1 border"
      style={{
        color: isSelected ? 'white' : 'black',
        background: isSelected ? '#6e6e6e' : 'transparent',
      }}
    >
      <p>{body}</p>
    </div>
  )
}
