/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 01/10/2021.
 */
import React, { Suspense } from 'react'
import { authStore } from '../../store/auth/authStore'
import { BellOutlined } from '@ant-design/icons'
import './header.scss'
import { HeaderSelectProject } from './HeaderSelectProject'
import { Button, Popover, Tooltip } from 'antd'
import { useProjects } from '../../hooks/useProjects'
import { useParams } from 'react-router-dom'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { analytics } from '../../util/analytics'

export const Header = ({ headerHeight }) => {
  return (
    <div className="">
      <div
        className="container-fluid bg-white position-relative px-4 "
        style={{
          height: headerHeight,
          borderBottom: '1px solid rgba(0,0,0,.1)',
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.04)',
        }}
      >
        <div className="row h-100 ">
          <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start align-items-center">
            <Suspense fallback={'...'}>
              <HeaderSelectProject></HeaderSelectProject>
            </Suspense>
          </div>

          <div className="col-12 col-md-4 d-flex justify-content-center align-items-center"></div>

          <div className="col-12 col-md-4 d-flex justify-content-end align-items-center">
            <Suspense fallback="...">
              <SettingsMenu></SettingsMenu>
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  )
}

const SettingsMenu = () => {
  const { projectId } = useParams() as { projectId: string }

  const user = authStore((state) => state.user)
  const logoutFunc = authStore((state) => state.logout)
  const name = user?.attributes.email || ''
  const letter = user?.attributes.email ? user?.attributes.email[0] : ''

  const { data } = useProjects()

  const logout = () => {
    analytics.track('LOGOUT')
    return post(ENDPOINTS.GLOBAL.logout, {})
      .then((result) => {
        logoutFunc()
      })
      .catch((error) => {
        alert(error.toString())
      })
  }

  const typeArr = projectId
    ? data.filter((user) => {
        //
        // This might happen if project has been deleted, but the reference is still connected to the user
        //
        if (!user?.projects) {
          return null
        }

        return user.projects._id === projectId
      })
    : ''

  const type = typeArr.length > 0 ? typeArr[0].type : ''

  const content = (
    <div className="px-2 mt-2 " style={{ width: 300 }}>
      <div className="d-flex mb-3 align-items-center">
        <div
          className="bg-primary rounded-circle p-2 d-flex justify-content-center align-items-center "
          style={{ width: 35, height: 35 }}
        >
          <div className="text-white " aria-label="Customise options" style={{ userSelect: 'none', outline: 'none' }}>
            <p className="fw-bold">{letter.toUpperCase()}</p>
          </div>
        </div>

        {type ? (
          <div className="ms-2">
            <p className="p-mini mb-0 fw-bold text-black text-capitalize">{type.toLowerCase()}</p>
            <p className="text-truncate" style={{ marginTop: -1, maxWidth: 240 }}>
              {name}
            </p>
          </div>
        ) : (
          <div className="ms-2">
            <p className="p-mini mb-0 fw-bold text-black text-capitalize"> </p>
            <p className="text-truncate" style={{ marginTop: -1, maxWidth: 240 }}>
              {name}
            </p>
          </div>
        )}
      </div>

      {/*  <div className="mb-2 " style={{ maxWidth: 300 }}>
        <p className="p-mini fw-bold text-black">EMAIL</p>
        <p className="text-truncate">{name}</p>
      </div>*/}
      {/*<hr className="my-1" />
      <Link to={`/project/${projectId}/profile`}>
        <Button type="primary" className="my-2 w-100">
          <p className="fw-bold">View Profile</p>
        </Button>
      </Link>*/}

      <Button onClick={logout} type="default" className="mt-2 w-100">
        <p className="fw-bold">Logout</p>
      </Button>
    </div>
  )

  const notificationContent = (
    <div className="px-2 " style={{ minWidth: 300 }}>
      <div className="mb-2 mt-2">
        <p className="p-small fw-bold text-black">Latest updates</p>
      </div>

      <div className="">
        <p className="text-black">You have no new notifications at this time</p>
      </div>
      {/*<div className="">
        <p className="p-mini fw-bold text-black">Yesterday</p>
        <p className="text-truncate">10 subjects Created </p>
        <p className="text-truncate">4 subjects Disqualified </p>
      </div>

      <div className="">
        <p className="p-mini fw-bold text-black mt-3">3 days ago</p>
        <p className="text-truncate">10 subjects Created </p>
        <p className="text-truncate">4 subjects Disqualified </p>
      </div>*/}
    </div>
  )

  return (
    <div className=" ">
      <div className="d-flex noselect align-items-center">
        <p className="p-small fw-bold mt-1  ms-1 me-1 text-capitalize">{type.toLowerCase()}</p>
        <Tooltip
          overlayClassName="mw-100"
          placement="bottom"
          title={<p className="py-1 px-2">{name}</p>}
          overlayInnerStyle={{ maxWidth: '100%', /* wordWrap: 'unset'*/ color: 'black' }}
          color={'#f3f3f3'}
        >
          <p className="p-small opacity-50 mt-1  ms-1 text-truncate" style={{ maxWidth: 170 }}>
            {name}
          </p>
        </Tooltip>

        <Popover placement="bottomRight" content={notificationContent}>
          <BellOutlined className="pointer mx-3" style={{ fontSize: '1.5rem' }} />
        </Popover>
        <Popover placement="bottomRight" content={content}>
          <div
            className="me-1 bg-primary rounded-circle p-2 d-flex justify-content-center align-items-center"
            style={{ width: 33, height: 33 }}
          >
            <button
              className="text-white "
              aria-label="Customise options"
              style={{ userSelect: 'none', outline: 'none' }}
            >
              <p className="fw-bold">{letter.toUpperCase()}</p>
            </button>
          </div>
        </Popover>
      </div>
    </div>
  )
}
