import React, { useEffect, useState } from 'react'

import { authStore } from './store/auth/authStore'
import { SpinnerFullHeight } from './components/spinner/SpinnerFullHeight'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorView } from './components/error/ErrorView'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { LoginPage } from './pages/login/LoginPage'
import { Main } from './Main'
import { ConfigProvider } from 'antd'
import { COLOR_PRIMARY } from './constants'
import { analytics } from './util/analytics'

console.log('App > force deploy ')
function App() {
  const user: any = authStore((state) => state.user)
  const init = authStore((state) => state.init)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!user) {
      init()
        .then((res) => {
          const userEmail = res?.attributes?.email
          const type = res?.attributes['custom:userType'] || 'unknown'

          if (userEmail) {
            analytics.identify({ email: userEmail, userType: type })
          }
        })
        .catch((error: any) => {
          console.log(' App > init = ', error)
          setError(error)
        })
    }
  }, [init, user])

  if (!user && !error) {
    return (
      <div className="d-flex flex-column h-50 justify-content-center ">
        <SpinnerFullHeight fullHeight={true}>
          <p className="my-3">Load user profile</p>
        </SpinnerFullHeight>
      </div>
    )
  }

  const shouldRedirect = !user || user.challengeName

  return (
    <>
      {/*{process.env.NODE_ENV !== 'production' ? <DebugGrid /> : null}*/}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: COLOR_PRIMARY,
          },
          components: {
            Popconfirm: {
              /* here is your component tokens */
              zIndexPopup: 5000,
            },
          },
        }}
      >
        <>
          {/*<GridDebugger></GridDebugger>*/}

          <BrowserRouter>
            <ErrorBoundary FallbackComponent={ErrorView}>
              <Routes>
                <Route path="/login/*" element={<LoginPage />} />
                <Route path="/*" element={shouldRedirect ? <Navigate replace to="/login" /> : <Main />} />
              </Routes>
            </ErrorBoundary>
          </BrowserRouter>
        </>
      </ConfigProvider>
    </>
  )
}

export default App
